/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'
import { useState } from "react"
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import InfiniteScroll from "react-infinite-scroller"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import SEO from '../../components/seo'
import { TagFacesTwoTone } from "@material-ui/icons"

interface RecipeRow {
  allRecipesCsvSheet1: {
    edges: {
      node: {
        title: string,
        url: string,
        date: string,
        point: string | null,
        tag: string | null
      }
    }[]
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

const Recipe = ({ data }: { data: RecipeRow }) => {
  const originalrecipeNodes = data.allRecipesCsvSheet1.edges
  const [recipeNodes, setRecipeNodes] = useState(data.allRecipesCsvSheet1.edges)
  const [recipeNodesRange, setSearchContentRange] = useState(10)
  const [enableRecipeNodesLoad, setEnableRecipeNodesLoad] = useState(true)
  const [searchContent, setSearchContent] = useState("")
  const [sorted, setSorted] = useState(false)

  const classes = useStyles()

  const loadMoreRecipes = () => {
    const maxRange = recipeNodesRange + 10
    setSearchContentRange(maxRange)
    if (recipeNodes.length < maxRange) setEnableRecipeNodesLoad(false)
  }

  const shuffle = () => {
    setRecipeNodes([...recipeNodes].sort(() => Math.random() - 0.5))
    setSearchContentRange(10)
    setEnableRecipeNodesLoad(true)

    trackCustomEvent({
      category: 'Recipe',
      action: 'Shuffle',
    })
  }

  const searchRecipe = (content: string) => {
    setRecipeNodes([...originalrecipeNodes].filter(node => {
      const title = node.node.title
      if (title == null) return false
      return title.includes(content)
    }))
    setSearchContentRange(10)
    setEnableRecipeNodesLoad(true)

    trackCustomEvent({
      category: 'Recipe',
      action: 'Search',
    })
  }

  const changeSearchContent = (e: any) => {
    const content = e.target.value
    setSearchContent(content)
    searchRecipe(content)
  }

  const search = (e: any) => {
    searchRecipe(searchContent)
  }

  const clickToKurashiru = (e: any) => {
    trackCustomEvent({
      category: 'Recipe',
      action: 'Kurashiru',
    })
  }

  if (!sorted) {
    const recipeNodesSorted = recipeNodes.sort((node1, node2) => {
      const dateStr1 = Number(node1.node.date.replace('/', '').replace('/', ''))
      const dateStr2 = Number(node2.node.date.replace('/', '').replace('/', ''))
      return dateStr2 - dateStr1
    })
    setRecipeNodes(recipeNodesSorted)
    setSorted(true)
  }
  const recipeCards = recipeNodes.map((node, i) => {
    if (recipeNodesRange < i) return
    if (node.node.url == null) return
    const url = node.node.url.replace(/\s/g, '')
    const urlSpritedBySlash = url.split('/')
    const id = urlSpritedBySlash[urlSpritedBySlash.length - 1]
    const imageUrl = `https://video.kurashiru.com/production/videos/${id}/compressed_thumbnail_square_large.jpg`
    return (
      <Grid container justify="center" style={{ padding: 10 }} key={i}>
        <Card className={classes.root}>
          <a href={url} target="_blank" onClick={clickToKurashiru}>
            <CardHeader
              title={node.node.title}
              subheader={`${node.node.date}に投稿`}
            />
            <CardMedia
              className={classes.media}
              image={imageUrl}
              title={node.node.title}
            />
          </a>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {node.node.point == null ? null : (
                <div>
                  {new Array(node.node.point).fill('').map(_ => <StarIcon />)}
                  {new Array(5 - Number(node.node.point)).fill('').map(_ => <StarBorderIcon />)}
                  <br />
                </div>)}
              {node.node.tag == null ? null : (
                <div>
                  {node.node.tag.split('/').map(tag => {
                    return <Chip label={tag} variant="outlined" style={{ marginTop: '7px', marginBottom: '7px', marginRight: '5px' }} />
                  })
                  }
                  <br />
                </div>)}
              <a href={url} target="_blank" onClick={clickToKurashiru}>レシピをみる</a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  })

  return (
    <Grid container justify="center" spacing={2}>
      <SEO />
      <Grid container justify="flex-end" xs={11} style={{ paddingTop: 5 }}>
        <a href="../wines">wines</a>
      </Grid>
      <Grid container justify="center" style={{ padding: 1 }}>
        <h1>recipes</h1>
      </Grid>
      <Grid container justify="center" style={{ padding: 1 }}>
        <p>masalifetokyoが料理したレシピを紹介</p>
      </Grid>
      <Grid container justify="center" style={{ padding: 10 }}>
        <Button variant="contained" onClick={shuffle}>シャッフルしてレシピをきめる</Button>
      </Grid>
      <br />
      <Grid container justify="center" style={{ padding: 10 }}>
        <Paper>
          <InputBase
            className={classes.input}
            placeholder="レシピを検索"
            inputProps={{ 'aria-label': 'レシピを検索' }}
            onChange={changeSearchContent}
          />
          <IconButton className={classes.iconButton} aria-label="search" onClick={search}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
      <br />
      <InfiniteScroll
        loadMore={loadMoreRecipes}
        hasMore={enableRecipeNodesLoad}
        loader={<div className="loader" key={0}>Loading ...</div>}>
        {recipeCards}
      </InfiniteScroll>
      <Grid container justify="center" style={{ padding: 10 }}>
        <a href="../">Home</a>
      </Grid>
    </Grid>
  )
}

export const query = graphql`
  query RecipeQuery {
    allRecipesCsvSheet1 {
      edges {
        node {
          date
          title
          url
          point
          tag
        }
      }
    }
  }
`

export default Recipe
